import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null
  },
  getters: {
    getUser: state => state.user,
    isAuthenticated: state => !!state.user
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    clearUser (state) {
      state.user = null
    }
  },
  actions: {
    setUser ({ commit }, user) {
      commit('setUser', user)
    },
    clearUser ({ commit }) {
      commit('clearUser')
    }
  },
  modules: {
  },
  plugins: [createPersistedState({
    storage: window.localStorage
  })]
})
