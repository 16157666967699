import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import Vue2Editor from 'vue2-editor'
import firebase from './firebase'
import moment from 'moment'
import VueMomentJS from 'vue-momentjs'

Vue.config.productionTip = false

Vue.use(VueSweetalert2)
Vue.use(Vue2Editor)
Vue.use(firebase)
Vue.use(VueMomentJS, moment)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

router.afterEach((to) => {
  document.title = to.meta.title || 'Church Of Christ Rumuomasi'
})
