const Home = () => import('../pages/indexPage.vue')
const About = () => import('../pages/aboutUs.vue')
const Contact = () => import('../pages/contactUs.vue')
const Blog = () => import('../pages/blogPage.vue')
const Gallery = () => import('../pages/galleryPage.vue')
const Event = () => import('../pages/eventPage.vue')
const LiveStream = () => import('../pages/liveStreamPage.vue')
const Article = () => import('../views/articlePage.vue')

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      layout: 'landingPageLayout',
      title: 'Home | Church Of Christ Rumuomasi'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      layout: 'landingPageLayout',
      title: 'About | Church Of Christ Rumuomasi'
    }
  },
  {
    path: '/contact-us',
    name: 'contactUs',
    component: Contact,
    meta: {
      layout: 'landingPageLayout',
      title: 'Contact-Us | Church Of Christ Rumuomasi'
    }
  },
  {
    path: '/blog',
    name: 'blog-page',
    component: Blog,
    meta: {
      layout: 'landingPageLayout',
      title: 'Blog | Church Of Christ Rumuomasi'
    }
  },
  {
    path: '/gallery',
    name: 'galleryPage',
    component: Gallery,
    meta: {
      layout: 'landingPageLayout',
      title: 'Gallery | Church Of Christ Rumuomasi'
    }
  },
  {
    path: '/event',
    name: 'eventPage',
    component: Event,
    meta: {
      layout: 'landingPageLayout',
      title: 'Gallery | Church Of Christ Rumuomasi'
    }
  },
  {
    path: '/liveStream',
    name: 'LiveStream',
    component: LiveStream,
    meta: {
      layout: 'landingPageLayout',
      title: 'Gallery | Church Of Christ Rumuomasi'
    }
  },
  {
    path: '/blog/:id',
    name: 'Article-Page',
    component: Article,
    props: true,
    meta: {
      layout: 'landingPageLayout',
      title: 'Article | Church Of Christ Rumuomasi'
    }
  }
]
export default routes
