// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCtjXq6Jtew3-8dqdUZ-bdJJwcoUk7KDvY',
  authDomain: 'finalrumuomasi.firebaseapp.com',
  projectId: 'finalrumuomasi',
  storageBucket: 'finalrumuomasi.appspot.com',
  messagingSenderId: '771477391601',
  appId: '1:771477391601:web:f2fedc72eb464c2effc150'
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
const auth = getAuth(app)
export const storage = getStorage(app)

export default { db, auth, signInWithEmailAndPassword, collection, getDocs, addDoc, storage }
